import React from 'react';
import {
    Box,
    Button,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const HomePage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                padding: theme.spacing(3),
                width: '100%',
            }}
        >
            <Typography
                variant={isMobile ? 'h4' : 'h3'}
                component="h1"
                gutterBottom
            >
                Home Page
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: theme.spacing(2),
                }}
            >
                <Button
                    variant="contained"
                    component={RouterLink}
                    to="/verb-quiz"
                    sx={{
                        textTransform: 'none',
                        fontSize: isMobile ? 'default' : 'h6',
                    }}
                >
                    Verb Quiz
                </Button>
                <Button
                    variant="contained"
                    component={RouterLink}
                    to="/verbDefinitionQuiz"
                    sx={{
                        textTransform: 'none',
                        fontSize: isMobile ? 'default' : 'h6',
                    }}
                >
                    Verb Definition Quiz
                </Button>
                <Button
                    variant="contained"
                    component={RouterLink}
                    to="/verb-glossary"
                    sx={{
                        textTransform: 'none',
                        fontSize: isMobile ? 'default' : 'h6',
                    }}
                >
                    Verb Glossary
                </Button>
            </Box>
        </Box>
    );
};

export default HomePage;

import getRandomInt from 'src/shared/utils/randomInt';
import { Glossary } from 'src/types/wordLibraryTypes';

const constructNewSentence = (
    wordLibraryArabic: Glossary,
): [string, string, string, VerbEntryArabic] => {
    const [verbIndex, tenseIndex, pronounIndex] = [
        getRandomInt(0, wordLibraryArabic.verbBank.length - 1),
        getRandomInt(0, wordLibraryArabic.tenseBank.length - 1),
        getRandomInt(0, wordLibraryArabic.pronounBank.length - 1),
    ];
    const verbObject = wordLibraryArabic.verbBank[verbIndex];
    const pronounObject = wordLibraryArabic.pronounBank[pronounIndex];
    const tenseObject = wordLibraryArabic.tenseBank[tenseIndex];

    // Assuming the structure of these objects, you'll need to adjust based on your actual types
    const tenseEnglish =
        tenseObject.meaning as keyof typeof verbObject.conjugations;
    const pronounEnglish =
        pronounObject.transliteration as keyof (typeof verbObject.conjugations)[typeof tenseEnglish];
    const pronounArabic = pronounObject.pronoun;

    const verbConjugationsWithTense = verbObject.conjugations[tenseEnglish];
    const fullyConjugatedVerbArabic =
        verbConjugationsWithTense[pronounEnglish].arabic;
    const VerbTransliteration =
        verbConjugationsWithTense[pronounEnglish].transliteration;

    const arabicTranslation = `${pronounArabic} ${fullyConjugatedVerbArabic}`;
    const transliteration = `${pronounObject.transliteration} ${VerbTransliteration}`;
    const englishTranslation =
        verbConjugationsWithTense[pronounEnglish].translation;

    return [arabicTranslation, englishTranslation, transliteration, verbObject];
};

export default constructNewSentence;

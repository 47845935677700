import { Box, Button, Typography } from '@mui/material';
import { Suspense, lazy, useEffect, useState } from 'react';
// import ConjugationTable from './ConjugationTableView';
import VerbSelection from '../common/VerbSelectionDropDown';
import { Glossary } from 'src/types/wordLibraryTypes';
import { Link as RouterLink } from 'react-router-dom';

type verbGlossaryProps = {
    wordLibrary: Glossary;
};

const ConjugationTable = lazy(
    () => import('../VerbGlossary/ConjugationTableView'),
);

const VerbGlossary: React.FC<verbGlossaryProps> = ({ wordLibrary }) => {
    const [selectedVerb, setSelectedVerb] = useState<VerbEntryArabic | null>(
        null,
    );

    useEffect(() => {
        if (selectedVerb) {
            setSelectedVerb(null);
        }
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                    mt: 2,
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    Select a Verb
                </Typography>
                <VerbSelection
                    verbs={wordLibrary.verbBank}
                    verbProperty="verb"
                    additionalverbProperties={['meaning']}
                    onSelect={setSelectedVerb}
                />
                {selectedVerb && (
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to={`/verb-quiz/${selectedVerb.verb}`}
                    >
                        Conjugation Quiz
                    </Button>
                )}
            </Box>

            {selectedVerb && (
                <Suspense fallback={<div>Loading...</div>}>
                    <>
                        <Typography
                            variant="h5"
                            sx={{ mt: 4 }}
                            gutterBottom
                        >
                            Conjugations for {selectedVerb.verb}
                        </Typography>
                        <ConjugationTable
                            verb={selectedVerb}
                            wordLibrary={wordLibrary}
                        />
                    </>
                </Suspense>
            )}
        </Box>
    );
};

export default VerbGlossary;

import VerbDefinitionQuiz from 'src/Components/VerbDefinitionQuiz';
import { Glossary } from 'src/types/wordLibraryTypes';

type VerbDefinitionProps = {
    wordLibrary: Glossary;
};

const VerbDefinitionQuizPage: React.FC<VerbDefinitionProps> = ({
    wordLibrary,
}) => {
    if (!wordLibrary) {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
            }}
        >
            <h1>Verb Quiz</h1>
            <VerbDefinitionQuiz wordLibraryArabic={wordLibrary} />
        </div>
    );
};

export default VerbDefinitionQuizPage;

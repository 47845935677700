import { Box, Typography, TextField, Button } from '@mui/material';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Glossary } from 'src/types/wordLibraryTypes';
import constructNewSentence from './utils/constructNewSentence';
const ConjugationTable = lazy(
    () => import('../VerbGlossary/ConjugationTableView'),
);

type VerbWorkParams = {
    wordLibraryArabic: Glossary;
};

const VerbWork = ({ wordLibraryArabic }: VerbWorkParams) => {
    const verbQuizGlossary = wordLibraryArabic;
    if (!wordLibraryArabic) {
        return <div> Data could not be retrieved</div>;
    }
    const [state, setState] = useState({
        answer: '',
        showAnswer: false,
        userInput: '',
        answerTranslation: '',
        verbObject: undefined as VerbEntryArabic | undefined,
        answerTransliteration: '',
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (verbQuizGlossary && !state.showAnswer) {
            setState((prevState) => ({ ...prevState, showAnswer: true }));
        } else if (verbQuizGlossary) {
            let [
                arabicTranslation,
                englishTranslation,
                transliteration,
                verbObject,
            ] = constructNewSentence(verbQuizGlossary);

            while (arabicTranslation === state.answer) {
                [
                    arabicTranslation,
                    englishTranslation,
                    transliteration,
                    verbObject,
                ] = constructNewSentence(verbQuizGlossary);
            }

            setState({
                ...state,
                showAnswer: false,
                userInput: '',
                answer: arabicTranslation,
                answerTranslation: englishTranslation,
                verbObject,
                answerTransliteration: transliteration,
            });
        }
    };

    useEffect(() => {
        const [
            arabicTranslation,
            englishTranslation,
            transliteration,
            verbObject,
        ] = constructNewSentence(verbQuizGlossary);

        setState({
            ...state,
            answer: arabicTranslation,
            answerTranslation: englishTranslation,
            answerTransliteration: transliteration,
            verbObject,
        });
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50rem',
                mx: 'auto',
                my: 2,
            }}
        >
            <Typography
                variant="h3"
                gutterBottom
            >
                {state.answerTranslation || 'Please submit an answer.'}
            </Typography>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                    marginBottom: '5rem',
                }}
            >
                <TextField
                    label="Your Answer"
                    variant="outlined"
                    value={state.userInput}
                    onChange={(e) =>
                        setState({ ...state, userInput: e.target.value })
                    }
                    disabled={state.showAnswer}
                    InputProps={{
                        style: { fontSize: '1.5rem', width: '35rem' },
                    }}
                    InputLabelProps={{
                        style: { fontSize: '1.5rem' },
                    }}
                />

                <Button
                    variant="contained"
                    type={'submit'}
                    sx={{
                        fontSize: '1.5rem', // Adjust button text size to match h2
                        padding: '10px 24px', // Optionally adjust padding to better fit larger text
                    }}
                >
                    {state.showAnswer ? 'Next' : 'Submit'}
                </Button>
            </form>

            <Typography
                variant="h3"
                gutterBottom
            >
                <Suspense fallback={<div>Loading...</div>}>
                    {verbQuizGlossary &&
                        state.answer &&
                        state.showAnswer &&
                        state.verbObject && (
                            <div>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                >
                                    {state.answer || 'Please submit an answer.'}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                >
                                    {state.answerTransliteration ||
                                        'Please submit an answer.'}
                                </Typography>

                                <ConjugationTable
                                    verb={state.verbObject}
                                    wordLibrary={verbQuizGlossary}
                                />
                            </div>
                        )}
                </Suspense>
            </Typography>
        </Box>
    );
};

export default VerbWork;

import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import {
    HomePage,
    VerbDefinitionQuizPage,
    VerbGlossaryPage,
    VerbQuiz,
} from './routes';
import VerbWork from './Components/VerbWork';
import { useData } from './context/glossaryContext';
import { useParams } from 'react-router-dom';
import { Glossary } from './types/wordLibraryTypes';

type VerbWorkWrapperProps = {
    wordLibraryArabic: Glossary;
};

const VerbWorkWrapper: React.FC<VerbWorkWrapperProps> = ({
    wordLibraryArabic,
}) => {
    const { verb } = useParams();

    // Filter the verbBank based on the verb from the path
    const verbObject = wordLibraryArabic.verbBank.find(
        (item: VerbEntryArabic) => item.verb === verb,
    );

    if (!verbObject) {
        return <div>Verb not found</div>;
    }
    const newWordLibraryArabic = wordLibraryArabic;
    newWordLibraryArabic.verbBank = [verbObject];

    return <VerbWork wordLibraryArabic={newWordLibraryArabic} />;
};

//TODO: Add a theme provider to the app
//FEATURES TO MAKE
//TODO: TELL THE TIME PRACTICE FEATURE INCLUDING ("DAYS", "MONTHS", "YEARS", "HOURS", "MINUTES", "SECONDS")
//TODO: ADD A FEATURE TO THE VERB QUIZ TO SHOW THE VERB IN THE PAST, PRESENT, AND FUTURE TENSES WHEN USER SUBMITS THE ANSWER
//TODO: ADD A FEATURE TO GLOSSARY TO QUIZ ON SPECIFIC VERBS
//TODO: ADD DIFFERENT TYPES OF WORDS TO THE GLOSSARY
//TODO: ADD NUMBER PRACTICE FEATURE
//TODO: ADD NUMBERS TO THE GLOSSARY
//TODO: ACCOUNT SYSTEM
//TODO: FEATURE TO VIEW WRONG ANSWERS
//TODO: FLASHCARD FEATURE
//TODO: math practice feature (addition, subtraction, multiplication, division)
//TODO: verbal number practice feature (what time is it, how many, how much, etc.)

const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
    },
});

function App() {
    const { data: wordLibraryArabic, loading } = useData();

    if (loading) {
        return <div>Loading...</div>;
    } else if (wordLibraryArabic == null) {
        return <div>Error: Could not retrieve data</div>;
    } else
        return (
            <ThemeProvider theme={theme}>
                <Stack
                    direction="column"
                    sx={{ height: '100vh', width: '100vw' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            width: '100%',
                        }}
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={<HomePage />}
                            />
                            <Route
                                path="/verb-quiz"
                                element={
                                    wordLibraryArabic && (
                                        <VerbQuiz
                                            wordLibrary={wordLibraryArabic}
                                        />
                                    )
                                }
                            />
                            <Route
                                path="/verb-glossary"
                                element={
                                    wordLibraryArabic && (
                                        <VerbGlossaryPage
                                            wordLibrary={wordLibraryArabic}
                                        />
                                    )
                                }
                            />
                            {wordLibraryArabic && (
                                <Route
                                    path="/verb-quiz/:verb"
                                    element={
                                        <VerbWorkWrapper
                                            wordLibraryArabic={
                                                wordLibraryArabic
                                            }
                                        />
                                    }
                                />
                            )}
                            <Route
                                path="/verbDefinitionQuiz"
                                element={
                                    wordLibraryArabic && (
                                        <VerbDefinitionQuizPage
                                            wordLibrary={wordLibraryArabic}
                                        />
                                    )
                                }
                            />
                        </Routes>
                    </Box>
                </Stack>
            </ThemeProvider>
        );
}

export default App;

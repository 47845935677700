import VerbWork from 'src/Components/VerbWork';
import { Glossary } from 'src/types/wordLibraryTypes';

type VerbQuizProps = {
    wordLibrary: Glossary;
};

const VerbQuiz: React.FC<VerbQuizProps> = ({ wordLibrary }) => {
    if (!wordLibrary) {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
            }}
        >
            <h1>Verb Quiz</h1>
            <VerbWork wordLibraryArabic={wordLibrary} />
        </div>
    );
};

export default VerbQuiz;

import React from 'react';
import VerbGlossary from '../Components/VerbGlossary'; // Adjust the path as necessary
import { Glossary } from 'src/types/wordLibraryTypes';

type verbGlossaryPageProps = {
    wordLibrary: Glossary;
};

const VerbGlossaryPage: React.FC<verbGlossaryPageProps> = ({ wordLibrary }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginTop: '3rem',
            }}
        >
            <h1>Verb Glossary</h1>
            <VerbGlossary wordLibrary={wordLibrary} />
        </div>
    );
};

export default VerbGlossaryPage;

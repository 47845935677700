import React, { createContext, useContext, useState, useEffect } from 'react';
import { Glossary } from 'src/types/wordLibraryTypes';

type DataContextState = {
    data: Glossary | null;
    loading: boolean;
    error: Error | null;
};

type DataProviderProps = {
    children: React.ReactNode;
};

const GlossaryContext = createContext<DataContextState | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [data, setData] = useState<Glossary | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        fetch('https://arabictutorserver-033b0207eb46.herokuapp.com/api/data')
            .then((response) => response.json())
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Failed to fetch data:', error);
                setError(error);
                setLoading(false);
            });
    }, []);

    return (
        <GlossaryContext.Provider value={{ data, loading, error }}>
            {children}
        </GlossaryContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(GlossaryContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};

import { Box, Select, MenuItem } from '@mui/material';
type VerbProperty = 'meaning' | 'transliteration';
const VerbSelection: React.FC<{
    verbs: VerbEntryArabic[];
    verbProperty: 'meaning' | 'transliteration' | 'verb';
    additionalverbProperties?: VerbProperty[];
    onSelect: (verb: VerbEntryArabic) => void;
}> = ({ verbs, verbProperty, additionalverbProperties, onSelect }) => {
    return (
        <Box sx={{ minWidth: 120 }}>
            <Select
                defaultValue=""
                onChange={(e) =>
                    onSelect(
                        verbs.find((verb) => verb.verb === e.target.value)!,
                    )
                }
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem
                    value=""
                    disabled
                >
                    Choose a verb
                </MenuItem>
                {verbs.map((verb, index) => (
                    <MenuItem
                        key={index}
                        value={verb.verb}
                    >
                        {verb[verbProperty]}
                        {additionalverbProperties &&
                            additionalverbProperties.map(
                                (additionalProperty) =>
                                    verb[additionalProperty],
                            )}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default VerbSelection;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6', // A nice shade of blue
            contrastText: '#fff', // White text for high contrast
        },
        secondary: {
            main: '#19857b', // A complementary shade of green
            contrastText: '#000', // Black text for contrast
        },
        background: {
            default: '#f4f6f8', // A light grey for the page background
            paper: '#ffffff', // White for card backgrounds
        },
        error: {
            main: '#e57373', // Red for errors
        },
        warning: {
            main: '#ffb74d', // Orange for warnings
        },
        info: {
            main: '#64b5f6', // Light blue for informational messages
        },
        success: {
            main: '#81c784', // Green for success messages
        },
    },
    typography: {
        fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(
            ',',
        ),
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
        },
        // Define other typography styles as needed
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Buttons use regular case, not uppercase
                    borderRadius: '8px', // Rounded button corners
                },
            },
        },
        // Define overrides for other components as needed
    },
    spacing: 8, // Default spacing is 8px
    // Add other customizations as needed
});

export default theme;

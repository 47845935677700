import { Box, Typography, Button } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { Glossary } from 'src/types/wordLibraryTypes';
import VerbSelection from '../common/VerbSelectionDropDown';

type VerbWorkParams = {
    wordLibraryArabic: Glossary;
};

const VerbDefinitionQuiz = ({ wordLibraryArabic }: VerbWorkParams) => {
    if (!wordLibraryArabic) {
        return <div> Data could not be retrieved</div>;
    }
    const verbBankVerbList = wordLibraryArabic.verbBank;

    const [state, setState] = useState({
        verbArabic: '',
        meaning: '',
        index: null as number | null,
        showAnswer: false,
        transliteration: '',
    });

    const setNewVerb = () => {
        const currentVerbIndex = Math.floor(
            Math.random() * verbBankVerbList.length,
        );

        setState({
            verbArabic: verbBankVerbList[currentVerbIndex].verb,
            meaning: verbBankVerbList[currentVerbIndex].meaning,
            index: currentVerbIndex,
            showAnswer: false,
            transliteration: verbBankVerbList[currentVerbIndex].transliteration,
        });
    };
    const [userVerbSelection, setUserVerbSelection] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (wordLibraryArabic && !state.showAnswer) {
            setState((prevState) => ({ ...prevState, showAnswer: true }));
        } else if (wordLibraryArabic) {
            setNewVerb();
        }
    };

    const handleVerbSelection = (verb: string) => {
        console.log(verb);
        const OnlyArabicPortion = verb.split(' ')[0];
        setUserVerbSelection(OnlyArabicPortion);
    };

    useEffect(() => {
        setNewVerb();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50rem',
                mx: 'auto',
                my: 2,
            }}
        >
            <Typography
                variant="h3"
                gutterBottom
            >
                {state.meaning || 'Please submit an answer.'}
            </Typography>

            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                    marginBottom: '5rem',
                }}
            >
                <VerbSelection
                    verbs={wordLibraryArabic.verbBank}
                    verbProperty="verb"
                    onSelect={(verb) => handleVerbSelection(verb.verb)}
                />

                <Button
                    variant="contained"
                    type={'submit'}
                    sx={{
                        fontSize: '1.5rem', // Adjust button text size to match h2
                        padding: '10px 24px', // Optionally adjust padding to better fit larger text
                    }}
                >
                    {state.showAnswer ? 'Next' : 'Submit'}
                </Button>
            </form>

            <Typography
                variant="h3"
                gutterBottom
            >
                <Suspense fallback={<div>Loading...</div>}>
                    {wordLibraryArabic &&
                        state.meaning &&
                        state.showAnswer &&
                        state.verbArabic && (
                            <div>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                >
                                    your answer: {userVerbSelection || ''}
                                </Typography>

                                <Typography
                                    variant="h3"
                                    gutterBottom
                                >
                                    answer: {state.verbArabic || ''}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                >
                                    Treansliteration:{' '}
                                    {state.transliteration ||
                                        'Please submit an answer.'}
                                </Typography>
                            </div>
                        )}
                </Suspense>
            </Typography>
        </Box>
    );
};

export default VerbDefinitionQuiz;
